import React, { ButtonHTMLAttributes } from 'react';
import cn, { flexIC, flexRow, gap2 } from "../ui/Tailwind";
import { FaLock, FaLockOpen, FaSave } from 'react-icons/fa';
import { MdEdit } from "react-icons/md";
import { RiArrowGoBackLine } from "react-icons/ri";
import { HiExternalLink } from "react-icons/hi";
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    color?: keyof typeof buttonColorList,
    borderColor?: keyof typeof buttonColorListOutline,
    customColor?: string,
    icon?: keyof typeof buttonIconList
    children?: React.ReactNode
    iconPosition?: "left" | "right"
    size?: keyof typeof sizeList,
    onlyIcon?: boolean
}

export const buttonColorList = {
    "blue": "btn btn-blue",
    "genericBlue": "btn-blue",
    "btnGreen": "btn category-tag-square tag-green",
    "tailwindBlue": "tw-bg-blue-500 tw-text-white hover:tw-bg-blue-600/80 disabled:hover:tw-bg-blue-500",
    "green": "category-tag-square tag-green",
    "red": "btn btn-danger",
    "genericRed": "btn-danger",
    "pastelRed": "btn tw-bg-red-200 tw-text-red-900 hover:tw-bg-red-300/80 disabled:hover:tw-bg-red-200",
    "pastelGreen": "tw-bg-green-200 tw-text-green-900 hover:tw-bg-green-300/80 disabled:hover:tw-bg-green-200",
    "pastelBlue": "tw-bg-blue-200 tw-text-blue-900 hover:tw-bg-blue-300/80 disabled:hover:tw-bg-blue-200",
    "pastelGray": " tw-bg-gray-200 tw-text-gray-900 hover:tw-bg-gray-300/80 disabled:hover:tw-bg-gray-200",
    "white": " tw-bg-white tw-text-black hover:tw-bg-gray-100 disabled:hover:tw-bg-white",
    "gray": "tw-bg-gray-500 tw-text-white hover:tw-bg-gray-600/80 disabled:hover:tw-bg-gray-500",
    "light-gray": " tw-bg-gray-200 tw-text-black hover:tw-bg-gray-300/80 disabled:hover:tw-bg-gray-200",
    "light-gray-100": " tw-bg-gray-100 tw-text-gray-500 hover:tw-bg-gray-200/80 disabled:hover:tw-bg-gray-100",
    "yellow": "tw-bg-yellow-500 tw-text-white hover:tw-bg-yellow-600/80 disabled:hover:tw-bg-yellow-500",
    "pastelYellow": " tw-bg-yellow-200 tw-text-yellow-900 hover:tw-bg-yellow-300/80 disabled:hover:tw-bg-yellow-200",
}

export const buttonColorListOutline = {
    "blue": "tw-border-blue-500 tw-text-blue-500 hover:tw-bg-blue-500 hover:tw-text-white",
    "green": "tw-border-green-500 tw-text-green-500 hover:tw-bg-green-500 hover:tw-text-white",
    "red": "tw-border-red-500 tw-text-red-500 hover:tw-bg-red-500 hover:tw-text-white",
    "pastelRed": "tw-border-red-200 tw-text-red-200 hover:tw-bg-red-200 hover:tw-text-red-900",
    "pastelGreen": "tw-border-green-200 tw-text-green-200 hover:tw-bg-green-200 hover:tw-text-green-900",
    "pastelBlue": "tw-border-blue-200 tw-text-blue-200 hover:tw-bg-blue-200 hover:tw-text-blue-900",
    "pastelGray": "tw-border-gray-200 tw-text-gray-200 hover:tw-bg-gray-200 hover:tw-text-gray-900",
    "pastelYellow": "tw-border-yellow-400 tw-text-yellow-400 hover:tw-bg-yellow-400 hover:tw-text-yellow-900",
    "white": "tw-border-white tw-text-white hover:tw-bg-white hover:tw-text-black",
    "gray": "tw-border-gray-500 tw-text-gray-500 hover:tw-bg-gray-500 hover:tw-text-white",
    "light-gray": "btn tw-border-gray-300 tw-text-gray-600 hover:tw-bg-gray-100 hover:tw-text-black",
    "light-gray-100": "btn tw-border-gray-100 tw-text-gray-100 hover:tw-bg-gray-100 hover:tw-text-gray-500",
    "yellow": "tw-border-yellow-500 tw-text-yellow-500 hover:tw-bg-yellow-500 hover:tw-text-white",
}

const textSizes = {
    "disable": "",
    "xs": "tw-text-xs",
    "sm": "tw-text-sm",
    "md": "tw-text-base",
    "xl": "tw-text-xl",
}

const sizeList = {
    "disable": { default: "tw-p-0", icon: 'tw-p-0' },
    "xs": {
        default: "tw-py-1 tw-px-2 tw-text-xs",
        icon: "tw-p-1 tw-text-xs"
    },
    "sm": { default: "tw-py-1 tw-px-2 tw-text-sm", icon: "tw-p-1 tw-text-sm" },
    "md": { default: "tw-py-2 tw-px-3 tw-text-base", icon: "tw-p-2 tw-text-base" },
    "xl": { default: "mt-2 mb-2 mt-md-0 mb-md-0 btn tw-text-base", icon: "tw-p-2 tw-text-base" },
}

export const buttonIconList = {
    "download": "cloud_download",
    "delete": "delete_outline",
    "add": "add",
    "playlist_add": "playlist_add",
    "search": "search",
    "save": "save",
    "save-fa": <FaSave />,
    "filter_list": "filter_list",
    "cancel": "cancel",
    "close": "close",
    "lock": <FaLock />,
    "lock_open": <FaLockOpen />,
    "arrow_upward": "arrow_upward",
    "delete_sweep": "delete_sweep",
    "edit": <MdEdit />,
    "back-ri": <RiArrowGoBackLine />,
    "external-link": <HiExternalLink />,
}

export default function Button({ onClick, size = "md", className, color, borderColor, customColor, iconPosition = "left", children, icon, onlyIcon, ...otherElements }: Readonly<ButtonProps>) {

    return <button type='button' {...otherElements} onClick={onClick} className={cn("tw-rounded tw-box-border tw-h-fit tw-w-fit",
        color && buttonColorList[color],
        borderColor && cn(buttonColorListOutline[borderColor], "tw-border tw-border-solid"),
        onlyIcon ? sizeList[size].icon : sizeList[size].default,
        customColor,
        "tw-duration-150 tw-transition-all tw-shadow-sm tw-ease-in-out active:tw-shadow-inner disabled:tw-cursor-not-allowed disabled:tw-shadow-none",
        "disabled:tw-opacity-50 disabled:tw-cursor-not-allowed disabled:active:tw-scale-100 ",
        className)}>
        <IconMaker {...otherElements} icon={icon} iconPosition={iconPosition}>
            {children}
        </IconMaker>
    </button>
}

const IconMaker = ({ children, ...otherElements }: ButtonProps): React.ReactElement => {

    if (!otherElements.icon)
        return <>{children}</>

    let renderIcon
    if (typeof buttonIconList[otherElements.icon] === 'string') {
        renderIcon = <i className="material-icons">{buttonIconList[otherElements.icon]}</i>
    } else {
        renderIcon = buttonIconList[otherElements.icon]
    }

    if (otherElements.iconPosition === "left")
        return <div className={cn(flexRow, flexIC, gap2)}>
            {renderIcon}
            {children}
        </div>
    return <div className={cn(flexRow, flexIC, gap2)}>
        {children}
        {renderIcon}
    </div>
}