import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { Log } from 'ng2-logger';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { CampusSearchInitialValues } from '../../store/constants/campus-const';
import { BuildingSearchInitialValues } from '../../store/constants/building-const';
import Spinner from '../../components/templates/spinner';
import Translator from '../../services/translate-factory';
import SketchesDetail from './sketches-detail';
import { TableProps } from 'antd';
import { Campus, TableType } from './tools/interfaces';
import CampusSketchList from './campus'
import BuildingSketchList from './building'
import cn, { flexCenter } from '../../components/ui/Tailwind';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { debounce } from 'lodash';
import * as GT from '../../tools/general-tools';
import { routes as Routes } from '../../store/constants/routes';
import { withRouter } from "react-router-dom";

type OnChange = NonNullable<TableProps<Campus>['onChange']>;
type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const T = Translator.create();

const L = Log.create('SketchesListPage');

interface SketchesListPageProps {
    dispatch?: any,
    match?: any,
    location?: any,
    publicSketches?: boolean,
    general_settings?: any,
}

const SketchesIn: React.FC<SketchesListPageProps> = ({
    dispatch,
    match,
    location,
    publicSketches = false,
    general_settings,
}) => {
    const [type, setType] = useState<string | null>()
    const [activeTab, setActiveTab] = useState('campus');
    const [page, setPage] = useState<any>()

    useEffect(() => {
        const dt = new URLSearchParams(window.location.search).get('dt');
        try {
            const params = atob(dt || '')
            const key = new URLSearchParams(params).get('key');
            if (key) {
                if (key === 'campus') {
                    setActiveTab('campus')
                } else if (key === 'building') {
                    setActiveTab('building')
                }
            }
        } catch (error) {
            L.error("dt params sorunu:", error)
        }
    }, [])

    const handleTabChange = (tab: string) => {
        if (page) {
            return goToSketches(tab);
        }
        setActiveTab(tab);
    };

    const goToSketches = (tab?: string) => {
        if (tab) {
            setPage(undefined)
            if (publicSketches) {
                return dispatch(Actions.Navigation(GT.Route(Routes.LOGIN + Routes.SKETCHES, `#${tab}`)));
            }
            dispatch(Actions.Navigation(GT.Route(Routes.SKETCHES, `#${tab}`)));
        } else {
            setPage(undefined)
            if (publicSketches) {
                return dispatch(Actions.Navigation(GT.Route(Routes.LOGIN + Routes.SKETCHES)));
            }
            dispatch(Actions.Navigation(GT.Route(Routes.SKETCHES)));
        }
    }

    const [, forceRender] = React.useReducer(x => x + 1, 0);

    const handleLanguageChange = React.useCallback(
        debounce(() => {
            forceRender(1)
        }, 1000),
        []
    );

    useEffect(() => {
        try {
            const { type } = match.params;

            if (type) {
                setType(type)
            } else {
                setType(null)
            }

        } catch (error) {
            L.error("useEffect Params okunamadi", error)
        }

    }, [location])

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        window.scrollTo(0, 0);
        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, [])

    useEffect(() => {
        try {

            setPage(<div className={cn(flexCenter, 'tw-h-screen')}><AiOutlineLoading3Quarters className='tw-animate-spin tw-text-2xl' /></div>)

            const { id } = match.params
            if (!id || !type)
                return setPage(undefined)

            switch (type) {
                case 'campus': {
                    // form: store.state.campus_page && store.state.campus_page.form ? store.state.campus_page.form : getInitialState(),
                    dispatch(Actions.ApiRequest(Constants.sketch.SKETCH_CAMPUS_GET_BY_ID, id, 'sketch-campus-form-spin',
                        (result: Types.IApiErrorResponse, status: number) => {
                            if (status === 200) {
                                setPage(<SketchesDetail
                                    key={id}
                                    returnText={(T) => T.t("gen_return_campus_selection")}
                                    type={type}
                                    publicSketches={publicSketches}
                                />)
                            } else {
                                return goToSketches()
                            }
                        }
                    ))
                    break;
                }

                case 'building': {
                    const { floor } = match.params

                    if (!floor)
                        return setPage(undefined)

                    const model = {
                        buildingId: id,
                        floor: floor
                    };

                    dispatch(Actions.ApiRequest(Constants.sketch.SKETCH_BUILDING_GET_BY_ID, model, 'sketch-building-form-spin',
                        (result: Types.IApiErrorResponse, status: number) => {
                            if (status === 200) {
                                setPage(<SketchesDetail
                                    key={id}
                                    returnText={(T) => T.t("gen_return_building_selection")}
                                    type={type}
                                    publicSketches={publicSketches}
                                />)
                            } else {
                                return goToSketches()
                            }
                        }
                    ))
                    break;
                }

                default:
                    setPage(undefined)

            }
        } catch (error) {
            L.error("useEffect Params okunamadi", error)
        }
    }, [type])

    const showCampusSettlements = () => {
        if (publicSketches) {
            return general_settings && general_settings.show_public_campus_settlements
        }
        return true
    }

    const showBuildingSettlements = () => {
        if (publicSketches) {
            return general_settings && general_settings.show_public_building_settlements
        }
        return true
    }

    useEffect(() => {
        if (!publicSketches) return

        // public sketches
        if (showCampusSettlements()) {
            return setActiveTab('campus')
        } else if (showBuildingSettlements()) {
            return setActiveTab('building')
        } else {
            return gotToLogin()
        }
    }, [])

    const gotToLogin = () => {
        dispatch(Actions.Navigation(GT.Route(Routes.LOGIN)))
    }

    return (
        <>
            <Spinner name='sketch-list-spin' />
            <div className="main list-campus">
                <div className="container-fluid">
                    {!publicSketches ? <h4 className="mb-4 mb-sm-0 tw-flex tw-items-center tw-gap-2">
                        {T.t("sketches")}
                    </h4> : null}
                    <nav className="inner-page-nav">
                        <div className={cn(
                            "mb-4 nav nav-tabs nav-fill",
                        )} id="nav-tab" role="tablist">
                            {showCampusSettlements() && <a
                                className={cn(
                                    "nav-item nav-link",
                                    activeTab === 'campus' ? 'active' : ''
                                )}
                                onClick={() => handleTabChange('campus')}
                                id="campus-tab"
                                data-toggle="tab"
                                href="#campus"
                                role="tab"
                                aria-controls="nav-home"
                                aria-selected={activeTab === 'campus'}
                            >
                                {T.t('campus_settlements')}
                            </a>}
                            {showBuildingSettlements() && <a
                                className={cn(
                                    "nav-item nav-link",
                                    activeTab === 'building' ? 'active' : ''
                                )}
                                onClick={() => handleTabChange('building')}
                                id="building-tab"
                                data-toggle="tab"
                                href="#building"
                                role="tab"
                                aria-controls="nav-home"
                                aria-selected={activeTab === 'building'}
                            >
                                {T.t('building_settlements')}
                            </a>}
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent" role='tablist'>
                        {showCampusSettlements() && <div className={cn(
                            "tab-pane fade",
                            activeTab === 'campus' ? 'show active' : '',
                        )} id="campus" role="tab" aria-labelledby="campus">
                            {
                                page || <CampusSketchList
                                    publicSketches={publicSketches}
                                    tableType={
                                        publicSketches
                                            ? TableType.PUBLIC_SKETCH_CAMPUS
                                            : TableType.SKETCH_CAMPUS
                                    } />
                            }
                        </div>}
                        {showBuildingSettlements() && <div className={cn(
                            "tab-pane fade",
                            activeTab === 'building' ? 'show active' : '',
                        )}
                            id="building" role="tab" aria-labelledby="building">
                            {
                                page || <BuildingSketchList
                                    publicSketches={publicSketches}
                                    tableType={
                                        publicSketches
                                            ? TableType.PUBLIC_SKETCH_BUILDING
                                            : TableType.SKETCH_BUILDING
                                    } />
                            }
                        </div>}
                    </div>
                </div>

            </div>

        </>
    );

}

const mapStateToProps = (store: Types.IPersistedState, ownProps: SketchesListPageProps): SketchesListPageProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const newProps: SketchesListPageProps = Object.assign({}, ownProps, {
        general_settings: store.state.general_settings,
    });
    return newProps;
};
const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(prev, next)
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const Sketches = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(withRouter(SketchesIn));

export default Sketches;
