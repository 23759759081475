import { Marker } from "react-image-marker";
import { ReactNode } from "react";
import { ISelectOption } from "../../../store/types";
import { buttonColorList, buttonIconList } from "../../../components/button";

// TODO public campus ve building icin api baglantisi ve reducer eklenmeli
export enum TableType {
    CAMPUS = "campus",
    BUILDING = "building",
    SKETCH_CAMPUS = "sketch_campus",
    PUBLIC_SKETCH_CAMPUS = "public_sketch_campus",//public sketch campus
    SKETCH_BUILDING = "sketch_building",
    PUBLIC_SKETCH_BUILDING = "public_sketch_building",//public sketch building
    AVALIABLE_EVENT = "AVALIABLE_EVENT",
}

export interface Campus { //burasi api ile gelen verilere entegre olacak
    id: number;
    campusName: string;
    connectedBuildings: Building[];
    address: string;
    headImage?: Image
    photos?: Image[]
    sketch: Image;
}

export interface Building { //burasi api ile gelen verilere entegre olacak
    id: number,
    name: string,
    photos?: string[], // eklenecek
    headImage?: Image //eklenecek
    marker?: Marker, // eklenecek
    relatedCampus?: string,
    address?: string,
    floors?: BuildingFloor[] //eklenecek
}

export interface BuildingFloor {
    id: string,
    floor: number,
    name: string,
    building_name?: string
    rooms: BuildingRoom[]
    sketches?: Image[];
    deleted?: boolean
}

export interface BuildingRoom {
    id: string,
    name: string,
    orderLine: number,
    marker?: Marker,
    photos: Image[]
}

export interface Image {
    id: string;
    src: string;
    name?: string
    alt?: string
    timestamp?: number
    deleted?: boolean
    isHeadImage?: boolean
}


export interface TableColumn {
    title?: string;
    parseName: string;
    __componentType: 'oneLine' | 'multiLine' | 'image' | 'chexbox' | 'activeness';
    __headerType: 'default' | 'sort' | 'checkAll'
    customRender?: (item: any) => ReactNode,
    customItemRender?: (item: any) => ReactNode,
    customImageSrc?: (item: any) => string,
    oneLineValue?: string;
    multiLineValues?: string[];
    imageModalValues?: string[];
    submitAction?: Action;
    translateMap?: ISelectOption[]
    widthPercentage?: number,
    maxLines?: number,
    displayPath?: string,
    allImages?: (item: any) => string[],
}

export interface Action {
    icon?: keyof typeof buttonIconList
    title?: string
    actionType: 'active/passive' | 'delete' | 'custom'
    color?: keyof typeof buttonColorList
    showIdle?: boolean;
    showOnSelect?: boolean;
    actionHandler?: (selectedIds: number[]) => void;
    type?: 'antd' | 'pure';
}

export interface MarkerNamed extends Marker {
    name: string
}