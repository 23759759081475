import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { BuildingFormInitialValues, BuildingFunctionalityOptions } from '../../store/constants/building-const';
import * as Types from '../../store/types';
import { BuildingFormValidation } from './validations/building-form-val';
import Translator from '../../services/translate-factory';
import ImageDropzone from '../../components/upload/ImageDropzone';
import { BuildingFloor } from '../sketches/tools/interfaces';
import Paginate from '../../components/table/paginate';
import cn, { flexCenter } from '../../components/ui/Tailwind';
import { SettingsEventModuleStatus } from '../../store/constants/setting-const';
import InputField from '../../components/Input/default-input';

const T = Translator.create();

const Logger = Log.create('BuildingForm');

function getInitialState(): Types.IBuildingFormState {
  const initialValues: Types.IBuildingFormState = {
    formikRef: React.createRef(),
    model: BuildingFormInitialValues,
    pageSize: 4,
  };
  return initialValues;
}

class BuildingForm extends Component<Types.IBuildingFormProps, Types.IBuildingFormState> {

  constructor(props: any) {
    super(props)
    this.state = getInitialState()
  }

  handlePageChange = (newPage: number) => {
    this.setState((prev) => ({ ...prev, currentPage: newPage }));
  };

  paginate = (items: any[], pageNumber: number, pageSize: number) => {
    const startIndex = (pageNumber - 1) * pageSize;
    return items.slice(startIndex, startIndex + pageSize);
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };


  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setCloseForm();
    }
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IBuildingItem, FormActions: FormikActions<Types.IBuildingItem>) => {
    this.disableAllChildren(true);

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        if (this.props.buildingId != undefined) {
          this.props.dispatch(Actions.ApiRequest(Constants.building.BUILDING_GET_BY_ID, this.props.buildingId, 'building-form-spin'));
        }
        this.setClose(true);
      }
      this.disableAllChildren(false);
      FormActions.setSubmitting(false);
    };

    if (this.props.buildingId) {
      this.props.dispatch(Actions.ApiRequest(Constants.building.BUILDING_UPDATE, model, 'building-form-spin', resultCallback));
    } else {
      this.props.dispatch(Actions.ApiRequest(Constants.building.BUILDING_CREATE, model, 'building-form-spin', resultCallback));
    }
  };

  static getDerivedStateFromProps(props: Types.IBuildingFormProps, state: Types.IBuildingFormState) {
    let hasNewState: boolean = false;
    if (props.buildingId && props.buildingId != state.model.building_id) {
      state.model.building_id = props.buildingId;
      props.dispatch(Actions.ApiRequest(Constants.building.BUILDING_GET_BY_ID, props.buildingId, 'building-form-spin'));
      hasNewState = true;
    }
    if (props.form && props.buildingId && props.buildingId == state.model.building_id) {
      state.model = props.form;
      if (props.form.campus) {
        state.model.campus_id = props.form.campus.value;
      }
      if (props.form && props.form.building_functionality) {
        let building_functionality_option = BuildingFunctionalityOptions(T).find((item: Types.ISelectOption) => item.value == props.form!.building_functionality);
        if (building_functionality_option == undefined && props.selectOptions) {
          building_functionality_option = props.selectOptions.additional_building_functionalities.find((item: Types.ISelectOption) => item.value == props.form!.building_functionality);
        }
        state.model.building_functionality_options = building_functionality_option != undefined ? [building_functionality_option] : [];
      } else {
        state.model.building_functionality_options = [];
      }
      hasNewState = true;
    }

    if (hasNewState) {
      return state;
    } else if (!props.buildingId && state.model.building_id) {
      return getInitialState();
    } else return null;
  }



  fixFloor(
    actions: FormikActions<Types.IBuildingItem>,
    newFloorCount: number
  ) {
    const values = this.state.formikRef.current && this.state.formikRef.current.state.values
    if (!values || !values.floors) return

    const filteredFloors = values.floors.filter(_ => !_.deleted)
    if (newFloorCount === undefined && newFloorCount < 1) {
      actions.setFieldValue("floors", [...values.floors].map(floor => ({
        ...floor,
        deleted: true
      })))
      return
    }
    const lastFloor = values.floors.length
    let updatedFloors = [...values.floors];
    if (newFloorCount > filteredFloors.length) {
      for (let i = filteredFloors.length; i < newFloorCount; i++) {
        updatedFloors.push({
          id: ("new-" + lastFloor + i).toString(),
          floor: i + 1,
          name: "",
          rooms: [],
          deleted: false,
        });
      }
    } else if (newFloorCount < filteredFloors.length) {
      let unRemovedFloorsCounter = 0
      updatedFloors = updatedFloors.map((floor, index) => {
        if (!floor.deleted) {
          unRemovedFloorsCounter += 1
        }
        if (unRemovedFloorsCounter - 1 >= newFloorCount) {
          return { ...floor, deleted: true };
        }
        return floor;
      });
    }
    updatedFloors = this.getRemovedFloors(updatedFloors)
    actions.setFieldValue("floors", updatedFloors);
  }

  getRemovedFloors = (floors: BuildingFloor[]) => {
    return floors.filter(_ => {
      if (_.id.startsWith("new-")) {
        return !_.deleted
      }
      return true
    })
  }

  updateFloor = (floor: BuildingFloor, newFloor: BuildingFloor) => {
    const values = this.state.formikRef.current && this.state.formikRef.current.state.values
    const setFieldValue = this.state.formikRef.current && this.state.formikRef.current.setFieldValue
    if (!values || !values.floors || !setFieldValue) return

    let updatedFloors = values.floors.map(f => f.id === floor.id ? newFloor : f) || [];
    setFieldValue("floors", updatedFloors);
    if (values.floor_count) {
      setFieldValue("floor_count", updatedFloors.filter(_ => !_.deleted).length);
    }
  };

  updateFloorImages = (floor: BuildingFloor, newImages: any) => {
    const values = this.state.formikRef.current && this.state.formikRef.current.state.values
    const setFieldValue = this.state.formikRef.current && this.state.formikRef.current.setFieldValue
    if (!values || !values.floors || !setFieldValue) return

    const updatedFloors = values.floors.map(f => f.id === floor.id ? { ...f, sketches: newImages } as BuildingFloor : f) || [];

    setFieldValue("floors", updatedFloors);
  };

  updateBuildingName = (floor: BuildingFloor, newTitle: string | undefined) => {
    const values = this.state.formikRef.current && this.state.formikRef.current.state.values
    const setFieldValue = this.state.formikRef.current && this.state.formikRef.current.setFieldValue
    if (!values || !values.floors || !setFieldValue) return

    const updatedFloors = values.floors.map(f => f.id === floor.id ? { ...f, name: newTitle } : f) || [];
    setFieldValue("floors", updatedFloors);
  };

  getBuildingFunctionalityOptions() {
    let buildingFunctionalityOptions = BuildingFunctionalityOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_building_functionalities && this.props.selectOptions.additional_building_functionalities.length > 0) {
      buildingFunctionalityOptions = [...BuildingFunctionalityOptions(T), ...this.props.selectOptions.additional_building_functionalities].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }
    return buildingFunctionalityOptions
  }

  fixPaginatedFloor = () => {
    if (this.state.currentPage && this.state.currentPage > 1) {
      this.setState(prev => ({ ...prev, currentPage: (prev.currentPage || 1) - 1 }))
    }
  }


  getFloorErrorMessage = (formikProps: FormikProps<Types.IBuildingItem>, id: string) => {
    const allFloors = formikProps.values.floors || [];
    const floorsWithErrors = formikProps.errors.floors || [];

    const allFloorsWithError = allFloors.map((floor, index) => {
      return {
        ...floor,
        errors: floorsWithErrors[index]
      }
    })

    for (const obj of allFloorsWithError) {
      if (obj && obj.id === id) {
        return obj.errors && obj.errors.name || undefined;
      }
    }

    return undefined;
  };

  getImageAndFloorSection = (values: Types.IBuildingItem, props: FormikProps<Types.IBuildingItem>) => {
    const { currentPage, pageSize } = this.state;
    const visibleFloors: BuildingFloor[] = this.paginate(values.floors != undefined ? values.floors.filter(_ => !_.deleted) : [], currentPage || 1, pageSize);
    if (visibleFloors.length === 0 && values.floors && values.floors.length > 0) {
      this.fixPaginatedFloor()
    }
    const { general_settings } = this.props
    return general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active && <>
      {
        this.props.term_id === -1 &&
        <div className="row mt-3">
          <div className="col-md-12">
            <ImageDropzone
              uploadAreaText={T.t('gen_you_can_import_images_here')}
              title={T.t('gen_images')}
              type="default"
              imageList={values.photos}
              setImages={(newImages) => props.setFieldValue("photos", newImages)}
              gridCol={2}
              selectableHeader
            />
          </div>
        </div>
      }
      {
        this.props.term_id === -1 && visibleFloors.filter(_ => !_.deleted).map((floor, index) => {
          return (
            <div
              key={floor.id}
              className="row mt-3"
            >
              <div className="col-md-12">
                <ImageDropzone
                  uploadAreaText={T.t('gen_sketch_image_upload')}
                  title={floor.name}
                  subTitle={T.t("gen_floor")}
                  type="building"
                  imageList={floor.sketches}
                  floor={floor}
                  setFloor={(newFloor) => this.updateFloor(floor, newFloor)}
                  maxFiles={1}
                  setImages={(newImages) => this.updateFloorImages(floor, newImages)}
                  setBuildingName={(newTitle) => this.updateBuildingName(floor, newTitle)}
                  editPlaceHolder={T.t('gen_enter_floor_name')}
                  gridCol={1}
                  errorMessage={props.submitCount > 0 ? this.getFloorErrorMessage(props, floor.id) : undefined}
                />
              </div>
            </div>
          )
        })
      }
      <hr />
      {values.floors && values.floors.filter((floor) => !floor.deleted).length <= 0 &&
        <div>
          <h6>{T.t("gen_floor_sketches")}</h6>
          <div className={cn(flexCenter, "tw-p-4 tw-opacity-40 tw-text-center")}>
            {T.t('gen_no_floor')}
          </div>
        </div>
      }
      {props.errors && props.errors.floors && props.submitCount > 0 && (
        <div className="error">
          {props.errors.floors.map((error, index) =>
            error ? <div key={index}>{T.t('gen_error_building_floor', { floor: index + 1, error: error.name })}</div> : null
          )}
        </div>
      )}
      {
        values.floors && values.floors.filter((floor) => !floor.deleted).length > 0 &&
        <div className="row-options justify-content-end">
          <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
            <Paginate
              filters={{
                page: currentPage || 1,
                size: pageSize,
                total: values.floors.filter((floor) => !floor.deleted).length,
              }}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      }
    </>
  }

  getFloorCountComponent = (values: Types.IBuildingItem, props: FormikProps<Types.IBuildingItem>) => {
    const { general_settings } = this.props
    return this.props.term_id === -1 && general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active && <>
      <div className="col-md-6 form-input form-group with-icon mt-3">
        <InputField
          id="floor_count"
          name="floor_count"
          min={0}
          step={1}
          max={20}
          disableSpecialCharList={[",", "."]}
          value={values.floor_count}
          onChange={(e) => {
            props.handleChange(e)

            if (Number(e.target.value) > 20) {
              props.setFieldError("floor_count", T.t('gen_floor_max_error'))
              props.setFieldValue('floor_count', 20)
              e.target.value = "20"
            }
            this.fixFloor(props, Number(e.target.value))
          }}
          onBlur={props.handleBlur}
          type="number"
          label={T.t('gen_floor_count')}
          error={props.errors.floor_count}
          showError={props.submitCount > 0}
        />
      </div>
      <div className="col-md-6" />
    </>
  }
  disableAllChildren = (isDisabled: boolean) => {
    const disableOnLoading = document.getElementById('disableOnLoading');

    if (disableOnLoading) {
      disableOnLoading.querySelectorAll('input, button, textarea, select').forEach((element) => {
        if (element instanceof HTMLInputElement ||
          element instanceof HTMLButtonElement ||
          element instanceof HTMLTextAreaElement ||
          element instanceof HTMLSelectElement) {
          element.disabled = isDisabled;
        }
      });
    }
  };

  render() {
    let buildingFunctionalityOptions = this.getBuildingFunctionalityOptions();
    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Formik
          enableReinitialize={true}
          ref={this.state.formikRef}
          initialValues={this.state.model}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={BuildingFormValidation(T)}
        >
          {(props: FormikProps<Types.IBuildingItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div id='disableOnLoading'>
                  <div id="addNew">
                    <div className="modal-dialog" role="document">
                      <Spinner name="building-form-spin" />
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">
                            {this.props.buildingId ? <><i className="mr-2 material-icons">edit</i>{T.t('gen_update_building')}</> : <><i className="mr-2 material-icons">add_circle_outline</i> {T.t('gen_add_building')}</>}
                          </h5>
                          <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseForm}>
                            <i className="material-icons">close</i>
                            <span>ESC</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-6 form-input form-group with-icon">
                              <input
                                id="building_code"
                                name="building_code"
                                value={values.building_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="building_code">{T.t('gen_code')}</label>
                              {errors && errors.building_code && props.submitCount > 0 && (
                                <div className="error">{errors && errors.building_code}</div>
                              )}
                            </div>
                            <div className="col-md-6 form-input form-group with-icon">
                              <input
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="name">{T.t('gen_name')}</label>
                              {errors && errors.name && props.submitCount > 0 && (
                                <div className="error">{errors && errors.name}</div>
                              )}
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3 add-custom-tag">
                                <div className="react-select-container">
                                  <label>{T.t('gen_campus')}</label>
                                  <Select
                                    className="react-select"
                                    name="campus_id"
                                    id="campus_id"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : []
                                    }
                                    placeholder={T.t('gen_select_campus')}
                                    value={props.values.campus}
                                    onChange={(option: any) => {
                                      props.setFieldValue('campus_id', option.value);
                                      props.setFieldValue('campus', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                  />
                                </div>
                                {errors && errors.campus_id && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.campus_id}</div>
                                )}
                              </div>
                            </div>

                            {this.props.general_settings && this.props.general_settings.event_module_status === SettingsEventModuleStatus.Active ? (
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t("gen_building_functionality")}</label>
                                    <Select
                                      className="react-select"
                                      name="building_functions"
                                      id="building_functions"
                                      isMulti={false} isClearable
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={buildingFunctionalityOptions}
                                      placeholder={T.t('gen_choose_building_functionality')}
                                      value={props.values.building_functionality_options}
                                      onChange={(option: any) => {
                                        props.setFieldValue('building_functionality_options', [option]);
                                        props.setFieldValue('building_functionality', option && option.value);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                    />
                                  </div>
                                  {errors && errors.building_functionality && props.submitCount > 0 && (
                                    <div className="error">{errors && errors.building_functionality}</div>
                                  )}
                                </div>
                              </div>
                            ) : <div className="col-md-6" />}
                            {this.getFloorCountComponent(values, props)}
                            <div className="col-md-12 form-input form-group with-icon">
                              <input
                                id="address"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="address">{T.t('gen_address')}</label>
                            </div>
                            <div className="col-md-12 form-input form-group with-icon">
                              <textarea
                                name="description"
                                className="form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={2}
                                id="description"
                                value={values.description}
                                placeholder={T.t('gen_description')}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="description" />
                              {errors && errors.description && props.submitCount > 0 && (
                                <div className="error">{errors && errors.description}</div>
                              )}
                            </div>
                          </div>
                          <div className="mt-3 row">
                            <div className="col-md-6">
                              <div className="text-left">
                                <h6>{T.t('gen_status')}</h6>
                                <div className="tick-radio position-relative d-inline-block">
                                  <Checkbox name="status" />
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.getImageAndFloorSection(values, props)}
                        </div>

                        <div className="modal-footer d-block">
                          <div className="row">
                            <div className="col-md">
                              <button
                                id='button_cancel'
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-gray min-auto"
                                aria-label="Close"
                                onClick={this.setCloseForm}
                              >
                                {T.t('gen_cancel')}
                              </button>
                            </div>
                            <div className="col-md text-md-right">
                              <button
                                onClick={() => props.handleSubmit()}
                                disabled={isSubmitting}
                                id='button_save'
                                type="button"
                                data-dismiss="modal"
                                aria-label="alert-success"
                                className="btn btn-green"
                              >
                                <i className="mr-2 material-icons">save</i> {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IBuildingFormProps): Types.IBuildingFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IBuildingFormProps = Object.assign({}, ownProps, {
    form: store.state.building_page && store.state.building_page.form ? store.state.building_page.form : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.buildingPage,
    term_id: store.state.term_id,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.building_page) {
    return (
      !!equal(prev.state.building_page && prev.state.building_page.form, next.state.building_page && next.state.building_page.form) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.buildingPage,
        next.state.select_options && next.state.select_options.buildingPage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(BuildingForm);

export default container;
