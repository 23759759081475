import { Image as AntImage } from 'antd';
import React from 'react';
import { MdCloudDownload, MdRemoveRedEye } from 'react-icons/md';
import { Image } from '../../../../pages/sketches/tools/interfaces';
import { Image as ImageEnum } from '../../../../pages/sketches/tools/interfaces';
import Button from '../../../button';
import Translator from '../../../../services/translate-factory';
import fallbackImage from '../../../../assets/images/fallback.jpg';
import { Badge } from '../../../ui/Badge';
import cn, { flexIC, flexJB, flexRow, gap2 } from '../../../ui/Tailwind';
import { GoStar, GoStarFill } from "react-icons/go";

interface ImageCardProps {
  image: Image
  allImages?: ImageEnum[];
  onDelete: () => void;
  onPreview?: () => void;
  onImageChange?: (images: ImageEnum[]) => void;
  selectableHeader?: boolean;
}

const ImageCard: React.FC<ImageCardProps> = ({ onPreview, image, onDelete, onImageChange, allImages, selectableHeader }) => {
  const T = Translator.create();
  const parseTimestamp = (timestamp: number) => {
    return new Date(timestamp * 1000)
      .toLocaleString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
  };

  const handleDownload = async () => {
    const fileURL = image.src;
    const fileName = image.name;
    try {
      const response = await fetch(fileURL);
      if (!response.ok) throw new Error('Download failed');

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName || 'downloaded-file';
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Download failed, opening in a new tab...', error);
      window.open(fileURL, '_blank');
    }
  };

  const isBase64Url = (url: string) => {
    return url.startsWith('data:');
  }

  const handleSelectHeadImage = () => {

    if (allImages) {
      const newImages = allImages.map((img) => {
        if (img.id === image.id) {
          return {
            ...img,
            isHeadImage: !img.isHeadImage
          }
        }
        return { ...img, isHeadImage: false };
      });
      onImageChange && onImageChange(newImages);
    }

  }


  return (
    <div className={cn('tw-group tw-items-center tw-bg-aplan-ground-gray tw-rounded-lg tw-p-4 tw-flex tw-flex-col tw-justify-between',
      'tw-duration-150',
      { 'tw-ring-2 tw-ring-green-500': image.isHeadImage }
    )}>
      <div className='tw-flex-grow tw-w-full tw-relative'>
        <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-bg-white tw-rounded">
          <AntImage
            preview={false}
            style={{
              objectFit: "contain",
              borderRadius: "4px",
              alignItems: "center",
              justifyItems: "center",
              maxHeight: "13rem"
            }}
            onClick={(e) => e.preventDefault()}
            src={image.src}
            fallback={fallbackImage}
            alt={image.alt} />
          {isBase64Url(image.src) && <Badge className='tw-absolute tw-left-2 tw-top-2'>{T.t("gen_new")}</Badge>}
        </div>
        <div className={cn(
          flexRow, flexIC, gap2, flexJB,
          'tw-absolute tw-top-2 tw-right-2 tw-pl-4 tw-w-full',

        )}>
          {selectableHeader && <Button onClick={handleSelectHeadImage}
            size='md'
            onlyIcon
            color={image.isHeadImage ? 'green' : 'white'}
            className={cn('tw-p-2 tw-shadow',
              image.isHeadImage ? 'tw-opacity-100' : 'tw-duration-150 tw-opacity-0 group-hover:tw-opacity-90',
            )}
            title={image.isHeadImage ? T.t("gen_head_image") : T.t("gen_set_head_image")}
          >
            {image.isHeadImage ? <GoStarFill /> : <GoStar />}
          </Button>}

          <div className={cn(flexRow, flexIC, gap2, 'tw-ml-auto',
            'tw-duration-150 tw-opacity-0 group-hover:tw-opacity-90',)}>
            <Button onClick={handleDownload}
              size='md'
              onlyIcon
              color='white'
              className='tw-p-2 tw-shadow'>
              <MdCloudDownload />
            </Button>
            {<Button
              size='md'
              onlyIcon
              color='white'
              onClick={onPreview} className='tw-p-2 tw-shadow'>
              <MdRemoveRedEye />
            </Button>}
          </div>
        </div>
      </div>
      <div className='tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-pt-2'>
        <div className='tw-w-48 tw-flex tw-flex-col'>
          <span title={image.name} className='tw-truncate tw-text-sm tw-pr-1'><b>{image.name}</b></span>
          <span className='tw-truncate tw-opacity-70 tw-text-xs'>{image.timestamp && parseTimestamp(image.timestamp)}</span>
        </div>
        <Button size='sm' icon='delete' className='tw-w-fit' color='genericRed' onClick={onDelete}>
          {T.t("gen_delete")}
        </Button>
      </div>
    </div>
  );
};

export default ImageCard;
